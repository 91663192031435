.signup-form .image {
  padding: 20px;
}


.wrapper {
  display: -webkit-box;
  display: -webkit-flex;
  display: -ms-flexbox;
  display: flex;
}

/*.slider {*/
/*  margin-top: 0px;*/
/*  margin-bottom: 0px;*/
/*  padding: 24px;*/
/*  border: 0px none transparent;*/
/*  border-radius: 0px;*/
/*}*/

.text-block {
  font-family: Poppins, sans-serif;
  color: #4a90e2;
  font-size: 18px;
  line-height: 30px;
  font-weight: 700;
}

.field-label {
  margin-bottom: 3px;
  font-family: Poppins, sans-serif;
  color: #4a90e2 !important;
  font-size: 12px;
  line-height: 20px;
  font-weight: 400;
  letter-spacing: 1px;
}

.field-label-2 {
  display: block;
  font-family: Poppins, sans-serif;
  color: #4a90e2 !important;
  font-size: 12px;
  font-weight: 400;
  letter-spacing: 1px;

}

.field-label-2:focus {
  background-color: transparent;
}

.field-label-2.margindo {
  margin-left: 10px;
}

.form-block {
  min-width: auto;
  margin-top: 48px;
}

.text-field {
  margin-bottom: 30px;
  padding-right: 10px;
  padding-left: 10px;
  border-radius: 4px;
  font-family: Poppins, sans-serif;
  color: #000;
  line-height: 24px;
  font-weight: 400;
}

.text-field::-webkit-input-placeholder {
  font-family: Poppins, sans-serif;
  color: #ced4da;
}

.text-field:-ms-input-placeholder {
  font-family: Poppins, sans-serif;
  color: #ced4da;
}

.text-field::-ms-input-placeholder {
  font-family: Poppins, sans-serif;
  color: #ced4da;
}

.text-field::placeholder {
  font-family: Poppins, sans-serif;
  color: #ced4da;
}

.text-field-2 {
  min-height: auto;
  min-width: 32%;
  margin-bottom: 30px;
  padding-right: 10px;
  padding-left: 10px;
  border-radius: 4px;
  font-family: Poppins, sans-serif;
  color: #000;
  line-height: 24px;
}

.text-field-2::-webkit-input-placeholder {
  font-family: Poppins, sans-serif;
  color: #ced4da;
  font-weight: 400;
}

.text-field-2:-ms-input-placeholder {
  font-family: Poppins, sans-serif;
  color: #ced4da;
  font-weight: 400;
}

.text-field-2::-ms-input-placeholder {
  font-family: Poppins, sans-serif;
  color: #ced4da;
  font-weight: 400;
}

.text-field-2::placeholder {
  font-family: Poppins, sans-serif;
  color: #ced4da;
  font-weight: 400;
}

.text-field-2.margin {
  min-width: 32%;
  margin-left: 10px;
}

.submit-button {
  display: block;
  margin-right: auto;
  margin-left: auto;
  border-radius: 4px;
  background-color: #4a90e2 !important;
  font-family: Poppins, sans-serif;
  font-size: 18px;
  line-height: 25px;
  font-weight: 500;
}

.div-block {
  border: 1px none #000;
  border-radius: 4px;
  background-color: #4a90e2;
}

.text-block-2 {
  display: block;
  margin-right: 0px;
  margin-left: 0px;
  padding-top: 7px;
  padding-bottom: 7px;
  font-family: Poppins, sans-serif;
  color: #fff;
  font-size: 16px;
  line-height: 24px;
  font-weight: 500;
  text-align: center;
}

.div-block-2 {
  display: -webkit-box;
  display: -webkit-flex;
  display: -ms-flexbox;
  display: flex;
  margin-top: 30px;
  -webkit-box-pack: justify;
  -webkit-justify-content: space-between;
  -ms-flex-pack: justify;
  justify-content: space-between;
  -webkit-box-align: stretch;
  -webkit-align-items: stretch;
  -ms-flex-align: stretch;
  align-items: stretch;
}

.link {
  font-family: Poppins, sans-serif;
  color: #4a90e2;
  font-size: 12px;
  line-height: 24px;
  text-decoration: none;
}

.link-2 {
  font-family: Poppins, sans-serif;
  color: #4a90e2;
  font-size: 12px;
  line-height: 24px;
  font-weight: 400;
  text-decoration: none;
}

.text-block-3 {
  display: block;
  min-width: auto;
  margin-top: 23px;
  font-family: Poppins, sans-serif;
  color: #4a90e2;
  font-size: 14px;
  line-height: 20px;
  font-weight: 400;
  text-align: center;
}

.div-block-3 {
  position: static;
  background-color: transparent;
}

.mask {
  position: static;
  max-width: 312px;
  min-height: 336px;
  min-width: auto;
  border: 0px none transparent;
  border-radius: 0px;
  color: transparent;
}

.text-block-4 {
  display: block;
  max-width: none;
  min-width: auto;
  margin-top: 18px;
  margin-right: 0px;
  margin-left: 0px;
  font-family: Poppins, sans-serif;
  color: #666666;
  font-size: 12px;
  line-height: 18px;
  text-align: center;
}

.slide {
  max-width: none;
  min-width: auto;
  margin-bottom: 0px;
  padding-bottom: 0px;
  color: transparent;
}

.slider-2 {
  max-width: 400px;
  min-height: 430px;
  min-width: auto;
  padding: 60px 44px;
  border: 0px none transparent;
  border-radius: 0px;
  background-color: #fff !important;
  color: transparent !important;
}

.image-2 {
  display: block;
  margin-right: auto;
  margin-left: auto;
  text-align: left;
}

.slide-nav {
  position: static;
  display: none;
  overflow: visible;
  -webkit-transition: opacity 200ms ease;
  transition: opacity 200ms ease;
}

.icon {
  color: #ced4da;
}

.icon-2 {
  color: #ced4da;
}

.image-3 {
  position: absolute;
  display: block;
  margin: 240px 20px 0px;
}

.image-4 {
  position: absolute;
  display: block;
  margin: 240px 20px 0px 50px;
}

.left-arrow {
  position: absolute;
  display: block;
}

.div-block-4 {
  display: -webkit-box;
  display: -webkit-flex;
  display: -ms-flexbox;
  display: flex;
  max-width: none;
  min-width: auto;
  -webkit-box-orient: vertical;
  -webkit-box-direction: normal;
  -webkit-flex-direction: column;
  -ms-flex-direction: column;
  flex-direction: column;
}

.image-5 {
  display: block;
  margin-right: auto;
  margin-left: auto;
}

.image-6 {
  display: block;
  margin-right: auto;
  margin-left: auto;
}

.text-block-5 {
  margin-top: 23px;
  font-family: Poppins, sans-serif;
  color: #4a90e2;
  font-weight: 400;
  text-align: center;
}

.div-block-5 {
  display: -webkit-box;
  display: -webkit-flex;
  display: -ms-flexbox;
  display: flex;
  min-width: auto;
  -webkit-box-orient: vertical;
  -webkit-box-direction: normal;
  -webkit-flex-direction: column;
  -ms-flex-direction: column;
  flex-direction: column;
  -webkit-box-pack: start;
  -webkit-justify-content: flex-start;
  -ms-flex-pack: start;
  justify-content: flex-start;
  text-align: center;
}

.text-block-6 {
  margin-top: 18px;
  font-family: Poppins, sans-serif;
  color: #666666;
  font-size: 12px;
  line-height: 18px;
  line-height: 18px;
}

.div-block-6 {
  display: -webkit-box;
  display: -webkit-flex;
  display: -ms-flexbox;
  display: flex;
  min-width: auto;
  -webkit-box-orient: vertical;
  -webkit-box-direction: normal;
  -webkit-flex-direction: column;
  -ms-flex-direction: column;
  flex-direction: column;
}

.text-block-7 {
  margin-top: 48px;
  font-family: Poppins, sans-serif;
  color: #4a90e2;
  text-align: center;
}

.text-block-8 {
  margin-top: 18px;
  margin-bottom: 60px;
  font-family: Poppins, sans-serif;
  color: #666666;
  font-size: 12px;
  line-height: 18px;
  text-align: center;
}

.div-block-7 {
  display: -webkit-box;
  display: -webkit-flex;
  display: -ms-flexbox;
  display: flex;
  margin-right: auto;
  margin-left: auto;
}

.div-block-8 {
  display: -webkit-box;
  display: -webkit-flex;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-pack: start;
  -webkit-justify-content: flex-start;
  -ms-flex-pack: start;
  justify-content: flex-start;
}

.div-block-9 {
  margin-left: 30px;
}

.div-block-9.margin {
  margin-left: 10px;
}

.div-block-10 {
  max-width: none;
}

.form-2 {
  min-width: auto;
}

/*.dropdown {*/
/*  display: block;*/
/*  min-width: auto;*/
/*  margin-bottom: 30px;*/
/*  border-style: solid;*/
/*  border-width: 1px;*/
/*  border-color: #ced4da;*/
/*  border-radius: 4px;*/
/*}*/

.text-block-9 {
  padding-left: 0px;
  font-family: Poppins, sans-serif;
  color: #ced4da;
  line-height: 24px;
}

/*.dropdown-toggle {*/
/*  display: block;*/
/*  padding: 8px 10px;*/
/*}*/

/*.dropdown-list {*/
/*  display: block;*/
/*  background-color: #e6eef6;*/
/*}*/

/*.dropdown-link {*/
/*  display: block;*/
/*  border: 0px none #000;*/
/*  font-family: Poppins, sans-serif;*/
/*  color: #4a90e2;*/
/*  line-height: 24px;*/
/*}*/

.div-block-11 {
  display: -webkit-box;
  display: -webkit-flex;
  display: -ms-flexbox;
  display: flex;
}

.div-block-12 {
  display: -webkit-box;
  display: -webkit-flex;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-pack: start;
  -webkit-justify-content: flex-start;
  -ms-flex-pack: start;
  justify-content: flex-start;
}

.icon-3 {
  color: #ced4da;
}

/*.dropdown-link-2 {*/
/*  font-family: Poppins, sans-serif;*/
/*  color: #4a90e2;*/
/*  line-height: 24px;*/
/*}*/

/*.dropdown-link-3 {*/
/*  font-family: Poppins, sans-serif;*/
/*  color: #4a90e2;*/
/*  line-height: 24px;*/
/*}*/

/*.dropdown-link-4 {*/
/*  font-family: Poppins, sans-serif;*/
/*  color: #4a90e2;*/
/*  line-height: 24px;*/
/*}*/

.button {
  display: block;
  padding: 7px 0px;
  /*border-radius: 4px;*/
  background-color: #3a80da !important;
  font-family: Poppins, sans-serif;
  color: #fff;
  font-size: 16px;
  line-height: 24px;
  font-weight: 500;
  text-align: center;
  width:100%;
  border-radius: 0.25rem !important;
}

.select-field {
  margin-bottom: 30px;
  padding: 8px 10px;
  background-color: transparent;
  font-family: Poppins, sans-serif;
  color: #000;
}

.select-field:hover {
  color: #000;
}

.select-field:active {
  color: #000;
}

.select-field:focus {
  background-color: transparent;
  color: #000;
}


@media (max-width: 479px) {

  .text-field-2.margin {
    margin-left: 0px;
  }
  .div-block-11 {
    display: block;
  }
}


label{
    font-weight: 400;
    letter-spacing: 2px;
    color: #4a90e2;
}

h3{
    font-weight: 700;
    margin-bottom: 20px;
    color: #4a90e2;
}

.settings-card{
    border-radius: 20px;
    background-color: #f8fbfd;
    box-shadow: 0 20px 24px 0 rgba(10, 31, 68, 0.15);
    height: 90%;
    
}

.settings-card .title{
        max-width: none;
        min-width: auto;
        margin-right: 0px;
        margin-left: 0px;
        padding: 17px 0px 7px;
        border-radius: 20px 20px 0px 0px;
        background-color: #4a90e2;
        color: #fff;
        font-size: 18px;
        line-height: 40px;
        font-weight: 700;
        text-align: center;
}



.conversation-list-item{
    box-shadow: inset 0 -1px 0 0 #ebe9e9;
    background-color: #fff;
    border-radius: 0.35rem;
    border: 1px solid #eee;
    height:78px;
    margin-bottom:2px;
}

.conversation-list-item:hover{
    background-color: #e0f2f1 ;
    cursor: pointer;

}


.conversation-thumb{
    height: inherit;
}

.conversation-list-item .time-stamp{
    margin-top:7px;
    color: #666;
    font-weight: 100;
    font-size: 12px;
}

.conversation-list-item .title{
    margin-top:7px;
    color: #333;
    font-weight: 400;
    font-size: 14px;
    display: block;

}

.conversation-list-item .message-text{
    color: #666;
    font-weight: 300;
    font-size: 14px;
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
    max-width: 200px;
}

.conversation-list-item img{
    max-height: 60px;
    max-width: 100%;
    vertical-align: center;
    margin: auto;
    display: block;

    /*margin-top: 10%;*/
}
.right{
    float:right;
}
.chat-container{
    display: flow-root;
}

.chat-messages-container{
    overflow-y: auto;
    overflow-x:hidden;
    flex-grow: 1;
}


.outgoing-message{
    background-color: rgba(196, 226, 255, 0.95);
    margin-bottom:0px;
    padding: 10px;
    width: 100%;
    text-align: left;
    border-radius: 0.65rem;
    border-bottom-right-radius: 0px;
    display: inline-block;
     /* These are technically the same, but use both */
     overflow-wrap: break-word;
     word-wrap: break-word;
 
     -ms-word-break: break-all;
     /* This is the dangerous one in WebKit, as it breaks things wherever */
     word-break: break-all;
     /* Instead use this non-standard one: */
     word-break: break-word;
 
     /* Adds a hyphen where the word breaks, if supported (No Blink) */
     -ms-hyphens: auto;
     -moz-hyphens: auto;
     -webkit-hyphens: auto;
     hyphens: auto;

    float:right;

}


.incoming-message{
    background-color: rgba(240, 240, 240,0.95);
    margin-bottom:10px;
    padding: 10px;
    max-width: 70%;
    min-width: 30%;
    text-align: left;
    border-radius: 0.65rem;
    border-bottom-left-radius: 0px;
    display: inline-block;

     /* These are technically the same, but use both */
    overflow-wrap: break-word;
    word-wrap: break-word;

    -ms-word-break: break-all;
    /* This is the dangerous one in WebKit, as it breaks things wherever */
    word-break: break-all;
    /* Instead use this non-standard one: */
    word-break: break-word;

    /* Adds a hyphen where the word breaks, if supported (No Blink) */
    -ms-hyphens: auto;
    -moz-hyphens: auto;
    -webkit-hyphens: auto;
    hyphens: auto;
}
#title{
    font-weight: 700;
}

#message{
    
}

#timestamp{
    font-size: smaller;
    color: #444;
    float: right;


    -webkit-user-select: none; /* Safari */        
    -moz-user-select: none; /* Firefox */
    -ms-user-select: none; /* IE10+/Edge */
    user-select: none; /* Standard */
}

#delivered{
    font-size: smaller;
    color: #444;
    float: left;

    -webkit-user-select: none; /* Safari */        
    -moz-user-select: none; /* Firefox */
    -ms-user-select: none; /* IE10+/Edge */
    user-select: none; /* Standard */
}

.dropzone{
    height: 20%;
    background: white;
    margin:20px;
    padding: 20px;
    max-height: 30vh;
    overflow: auto;
}

.dropzone img{
    max-height: 20%;
    /* width:auto; */
}


.smart-reply-container{
    height: 40%;
    background: white;
    margin:20px;
    padding: 20px;
    max-height: 50vh;
    overflow: auto;
}


.media-attachment-buttons{
    background: none;
    /* width: 100%; */
    min-width: 10px;
    border: none;
    color:white !important;
}


.z-1000{
    z-index: 1000;
}


.media-viewer-box{
    max-width: 50vw;
}

.send-button{
    position: absolute;
    right:30px;
    z-index:100;
    bottom:7px;
    background: #4a90e2 !important;
    color:white !important;


}


.send-button:hover{
    position: absolute;
    z-index:100;
    background: #4867b7 !important;

}
.emoji-button{
    position: absolute;
    right:80px;
    z-index:100;
    bottom:7px;
}


.attach-button{
    position: absolute;
    bottom:7px;
    right:130px;
    z-index:100;
}


.schedule-button{
    position: absolute;
    right:180px;
    z-index:140;
    bottom:7px;
}

.smart-reply-button{
    position: absolute;
    right:230px;
    z-index:100;
    bottom:7px;
}

.suggestions-button{
    position: absolute;
    right:280px;
    z-index:100;
    bottom:7px;
}

.message-input-row{
    width: 100%;
    display: flex;
    flex-flow: row;
}

.message-input-area{
    position: relative;
    flex-grow:1;

}

.message-input-buttons{
    width: 240px;

}
@media only screen and (max-width: 768px) {
    .send-box{
        border-bottom-left-radius: 0px !important;
        border-bottom-right-radius: 0px !important;
    }



  }

.media-viewer-modal{
    background-color: rgba(0,0,0,0.8);
    border-radius: 0px !important;
}

.media-viewer-modal .modal-body{
    padding:0px;
    background: none;

}

.media-viewer-modal .modal-content{
    padding:0px;
    background: none;
}

.media-viewer-modal .close-button{
    font-size: 20px;
    position: absolute;
    right:-10px;
    top:-10px;
    background-color: black;
    border-radius: 50%;
    padding: 0px;
    color: white;
}
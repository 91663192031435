.optout-list-container{
    position: relative;
    width:100%;
    height: 98%;
    border-bottom-left-radius:1.5rem;
    border-bottom-right-radius:1.5rem;
    /* filter: alpha(opacity=50); */
    background-repeat: no-repeat;
    background-size: cover;
        overflow-x: hidden;
        overflow-y: auto;
   
}


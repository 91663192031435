.signin-align{
    text-align: left;
}

@media only screen and (max-width: 768px) {
    .signin-align{
        text-align: center !important;
    }
}

#slybroadcast-signin-form label, .slybroadcast-link{
    color:#2ecc71;
}

.slybroadcast-link:hover{
    color:#389660 !important;

}

#slybroadcast-signin-form .btn-primary{
    background-color:#2ecc71 !important;
    border-color:#2ecc71 !important;

}

#slybroadcast-signin-form .btn-primary:hover{
    background-color:#389660 !important;
    border-color:#389660 !important;

}
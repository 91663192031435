.settings-container{
    position: relative;
    width:100%;
    height: 98%;
    border-radius: 1.5rem;
    /* filter: alpha(opacity=50); */
    background-repeat: no-repeat;
    background-size: cover;
    box-shadow: -1px -1px 10px 2px #ccc;
}

.settings-content{
    overflow-y: scroll;
    height: 80%;
    position: relative;
}
.settings-container input{
    opacity: 0.85;
}

.settings-button-footer{
    position: absolute;
    bottom:0px;
    border-bottom-left-radius:1.5rem;
    border-bottom-right-radius: 1.5rem;
    background-color:#f8fbfd;
    box-shadow: 0 20px 24px 0 rgba(10, 31, 68, 0.15);
    width: 100%;
    padding-left: 20px;
    padding-right: 20px;
    min-height: 60px;
    border-top: 1px solid #53d1c1;
}

.left{
    float: left;
    display: block;
}

.right{
    float: right;
    display: block;
}

.csv-reader-input label{
    color:#343a40 !important;
    font-weight: 400;

}

.login-placeholder{
    background: url("../images/sample.png");
    height: calc(100vh - 50px);
    background-repeat: no-repeat;
    background-size: contain;
    background-position: center;
}


.signin-gradient{
    background: #3a7bd5;  /* fallback for old browsers */
    background: -webkit-linear-gradient(to right, #3a6073, #3a7bd5);  /* Chrome 10-25, Safari 5.1-6 */
    background: linear-gradient(to right, #3a6073, #3a7bd5); /* W3C, IE 10+/ Edge, Firefox 16+, Chrome 26+, Opera 12+, Safari 7+ */

}


